import { Helmet } from 'react-helmet';

import about_hero from "../images/components/Hero/motherboard_hero_image.webp";
import SurveyFooter from "../components/universal/SurveyFooter/SurveyFooter";
import history_image from "../images/pages/about/history.webp";
import team_image from "../images/pages/about/team_photo.webp";
import our_mission_image from "../images/pages/about/tower_bridge_image.webp";

import BlogCardSwiper from '../components/pages/resources/BlogCardSwiper/BlogCardSwiper';

import "./About.css";

function About() {
    return (
        <div>
			<Helmet>
				<title>About Mission Encodeable | Inspiring future computer scientists</title>
				<meta name="description" content="Mission Encodeable offers free courses in coding, design and digital making for young people across the world." />
			</Helmet>

			<div className="powder-blue-bg">
				<section className='about-hero'>
					<div className='text'>
						<h1 className="darkgreen">We're on a mission to <em>inspire</em> future computer scientists</h1>
						<p className="darkgreen">As a student-led not-for-profit, we're creating free, creative tutorials that make coding accessible to students everywhere.</p>
					</div>

					<div className="hero-image-wrapper">
						<img src={about_hero} className="hero-img" alt="A close up of a computer's motherboard" />
					</div>
				</section>
			</div>

			<section className="about-section">
				<div className="about-section-text">
					<h2 className="darkgreen">Our <u>history</u></h2>
					<p>Mission Encodeable started with us having a conversation about our experiences learning to code in school. While both of us really enjoyed coding, we noticed that it wasn’t always presented in a way that sparked the same enthusiasm in others.</p>
					<p>We were looking for a new project to work on together – ideally something that could have an impact on others. We saw an opportunity to create a platform that made coding more creative and accessible, so we set about making a prototype and asked our friends and family to give us some feedback to help us really understand how best to make coding fun to learn for our fellow students.</p>
				</div>
				
				<img src={history_image} alt="Harry and Anna Wake, the founders of Mission Encodeable, sat at a kitchen table working on a prototype of Mission Encodeable."/>
			</section>

			<section className="about-section">
				<img src={our_mission_image} alt="Tower Bridge in London"/>
				<div className="about-section-text">
					<h2 className="darkgreen">Our <u>mission</u></h2>
					<p>We aim to make coding accessible, engaging, and impactful for students worldwide. By providing free, project-based tutorials, we hope to spark curiosity and creativity in students, helping them see the real-world possibilities of coding and giving them the skills to create programs that interest them.</p>
				</div>
			</section>

			<section className="about-section">
				<div className="about-section-text">
					<h2 className="darkgreen">The <u>team</u></h2>
					<p>We are Harry Wake and Anna Wake: two cousins passionate about coding and the creators of Mission Encodeable. We founded this website as 14-year-old students, and ever since we’ve been working together to add new tutorials and resources, and to make our existing ones the best that they can be.</p>
				</div>
				
				<img src={team_image} alt="Harry and Anna Wake, the founders of Mission Encodeable, in front of a colourful Thierry Noir wall graffiti artwork"/>
			</section>

            <section className="home-resources">
                <p className="mini-section-heading mini-section-heading-centred">Blog</p>
                <h3 className="resources-section-intro"><u>Discover the latest</u> blog posts from the Mission Encodeable team</h3>

                <section className='home-blog-posts' id="blog-posts">
                    <BlogCardSwiper />
                </section>


            </section>

			<SurveyFooter/>
    	</div>
    );
};

export default About;