import React from "react"
import Error from "./Error.js"
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import "./FinishLegacy.css";

function Finish() {

    let params = useParams();
    let level_number = params.levelNumber;

    if (level_number >= 6) {
        return (<Error />)
    } else {
        return (
            <div>
    
            <Helmet>
                <title>Level {level_number} Finish | Mission Encodeable</title>
            </Helmet>
    
            <section className="finish-header">
                <h1>Congratulations on completing Python level {level_number}!</h1>
            </section>
    
            <section className="finish_certificate_download">
                <h3>Download your certificate</h3>
                <img className="certificate_image" alt="Python certificate" src={`../../../certificates/python_legacy_level_${level_number}_certificate_image.png`}/>
                <br/>
                <a href={`../../../certificates/python_legacy_level_${level_number}_certificate.pdf`} download><button className="btn_orange_fill">Download</button></a>
            </section>
    
            <section className="end_of_level_survey">
    
            <div className="end_of_level_survey_text">
                <h3>Share your feedback</h3>
                <p>Let us know what you thought of this level by filling in our short feedback survey.</p>
            </div>
    
                <a className="survey_button" href="https://tally.so/r/wajzRE" target="_blank" rel="noreferrer"><button className="btn_orange_fill">Take the survey</button></a>
            </section>
    
    
            </div>
        )
    }
}

export default Finish;