import React from "react"
import Error from "./Error.js"
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';

import "./Finish.css";

function Finish() {
    let navigate = useNavigate();
    let params = useParams();
    let level_number = params.levelNumber;
    const host = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
    const url = `${host}/api/certificate/python/${level_number}`;

    if (level_number > 10) {
        return (<Error />)
    } else {
        return (
            <div>

                <Helmet>
                    <title>Level {level_number} Finish | Mission Encodeable</title>
                </Helmet>

                <section className="finish-header">
                    <h1>Congratulations on completing Python level {level_number}!</h1>
                </section>

                <section className="finish_certificate_download">
                    <h3>Download your certificate</h3>
                    <div className="form_wrapper">
                        <div className="finish_form">
                            <form action={url} method="get" autoComplete="new-off" id="certificate-search-form">
                                <label for="for">Your name:</label>
                                <input type="text" id="for" name="for" data-lpignore="true" required />
                                <button type="submit" className="btn_orange_fill">Download</button>
                                <p className="dp_info">Please note your name and the PDF certificate will not be saved on our systems. Your name must be in English / European letters.</p>
                            </form>
                        </div>
                    </div>
                </section>

                {level_number < 9 && <div className="start-section">
                    <h2>Ready to start Python level {parseInt(level_number) + 1}?</h2>
                    <button onClick={() => { navigate(`/python/level/${parseInt(level_number) + 1}`); }} className="btn_green_outline">Start level</button>
                </div>
                }


                <section className="end_of_level_survey">

                    <div className="end_of_level_survey_text">
                        <h3>Share your feedback</h3>
                        <p>Let us know what you thought of this level by filling in our short feedback survey.</p>
                    </div>

                    <a className="survey_button" href="https://tally.so/r/wajzRE" target="_blank" rel="noreferrer"><button className="btn_orange_fill">Take the survey</button></a>
                </section>


            </div>
        )
    }
}

export default Finish;
