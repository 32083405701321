import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import './Home.css';
import "../index.css"

import hero_image from "../images/components/Hero/homepage_hero.webp";
import how_it_works_image from "../images/pages/home/how_it_works.webp"
// import reduce_costs_icon from "../images/universal/icons/reduce_costs_icon.svg";
// import uplevel_engagement_icon from "../images/universal/icons/uplevel_icon.svg";
// import save_time_icon from "../images/universal/icons/save_time_icon.svg";
import about1 from "../images/pages/home/about1.webp";
import about2 from "../images/pages/home/about2.webp";
import cnd_long_logo from "../images/pages/teacher_page/long_logo_cnd.svg";
import brushstroke from "../images/universal/annotations/brushstroke.svg";

import FeaturedLogos from "../components/universal/FeaturedLogos/FeaturedLogos";
import NewsletterFooter from "../components/universal/NewsletterFooter/NewsletterFooter";
import BenefitsCard from "../components/universal/BenefitsCard/BenefitsCard.js";
import BlogCardSwiper from "../components/pages/resources/BlogCardSwiper/BlogCardSwiper.js";

function App() {

	// const [showVideo, setShowVideo] = useState(false)

	return (
		<div>

			<Helmet>
				<title>Mission Encodeable | Free coding tutorials for young people</title>
				<meta name="description" content="Mission Encodeable offers free project-based, creative and engaging coding tutorials for young people across the world." />
			</Helmet>

			<section className='home_hero'>
				<div className='text'>
					<h1 className="darkgreen">The <em>coolest</em> way to learn to code</h1>
					<p className="darkgreen">Free project-based, creative and engaging coding tutorials for young people.</p>
					{/* <button className='btn_video_inline' onClick={() => setShowVideo(true)}><img className="play_triangle" src={play_triangle}/><u>WATCH THE VIDEO</u></button> */}
					<br />
					<Link to="/get-started"><button className='btn_orange_fill'>Get started</button></Link>
					<Link to="/teachers"><button className='btn_orange_outline'>Info for teachers</button></Link>
					{/* <VideoModal onClose={() => setShowVideo(false)} showVideo={showVideo} videoLink="https://www.youtube-nocookie.com/embed/1rzpdFUXBDQ"/> */}
				</div>
				<div className="hero_image_wrapper">
					<img src={hero_image} alt="A mockup of the Mission Encodeable coding tutorials on a Macbook and iPhone" className="hero-img" />
				</div>
			</section>

			<Link to="/craig-n-dave-partnership" className="a-no-decoration">
				<div className="cnd-partnership">
					<div className="cnd-partnership-home-brushstroke cnd-partnership-brushstroke">
						<img src={brushstroke} alt=""/>
					</div>
					<div className="cnd-partnership-home-logo-text cnd-partnership-logo-text">
						<p>In partnership with:</p>
						<img src={cnd_long_logo} alt="Craig 'n' Dave logo"/>
					</div>
				</div>
			</Link>

			<section className='about'>
				<div className='about_block'>
					<h3 className='text white'>Our mission is to inspire future computer scientists</h3>
					<Link to="/about"><button className='btn_orange_fill'>About us</button></Link>
				</div>
			</section>


			{/* <section className="home-benefits">
				<p className="mini-section-heading">The benefits</p>
				<h3 className='darkgreen engaging-tutorials-benefit-padded'>Engage your students with our free online <u>tutorials</u></h3>
				<p className="darkgreen">Our tutorials take a creative, project-based approach.</p>

				<div className="values_wrapper">

					<BenefitsCard
						benefitIcon={save_time_icon}
						benefitTitle="Save time"
						benefitDescription="Free up your planning time and take control of your workload."
					/>

					<BenefitsCard
						benefitIcon={uplevel_engagement_icon}
						benefitTitle="Uplevel engagement"
						benefitDescription="Mission Encodeable offers a focus on practical coding exercises and projects."
					/>

					<BenefitsCard
						benefitIcon={reduce_costs_icon}
						benefitTitle="Reduce costs"
						benefitDescription="Our resources are all freely available, so there's no need to worry about budget."
					/>
				</div>


			</section> */}

			<section className='how_it_works'>

				<div className='how_it_works_image_wrapper'>
					<img src={how_it_works_image} alt="An abstract illustration of the Mission Encodeable coding tutorials on a laptop" />
				</div>

				
				<div className="text">
					<h6 className="mini-section-heading">How it works</h6>
					<h2 className='darkgreen'>Free coding <u>tutorials</u></h2>

					<div className="how-it-works-step">
						<div className="step-text">
							<h5>Choose a level</h5>
							<p>Our course is broken into a series of simple and well-explained levels. Befoe you begin, choose which level is most appropriate for you, or, if you're a beginner, start at Level 1.</p>
						</div>
					</div>


					<div className="how-it-works-step">
						<div className="step-text">
							<h5>Learn to make projects</h5>
							<p>In each level, we'll teach you the core programming fundamentals, and walk you through a number of projects, so you can put what you've learnt into practice.</p>
						</div>
					</div>

					<div className="how-it-works-step">
						<div className="step-text">
							<h5>Get your certificate</h5>
							<p>After you've completed the independent capstone project at the end of the level, you'll recieve a personalised certificate.</p>
						</div>
					</div>


					<br />

					<Link to="/get-started"><button className="btn_green_outline">Get started</button></Link>

				</div>
			</section>

			<section className="featured-in">
				<p className="mini-section-heading">Why trust us?</p>
				<h3 className="featured-heading-white">We've been <u>featured</u> by industry experts & press</h3>
				<FeaturedLogos />
			</section>

			<section className="about-photos-block">
				<div className="about-left">
					<div className="about-text">
						<p className="mini-section-heading">About us</p>
						<h3>We're on a mission to inspire future computer scientists</h3>
						<p>As a student-led not-for-profit, we're creating free, creative tutorials that make coding accessible to students everywhere.</p>
						<Link to="/about"><button className='btn_green_outline'>About us</button></Link>
					</div>
					<img src={about1} alt="Harry and Anna Wake, the founders of Mission Encodeable, sat at a table on their laptops, with a plant in the foreground" loading="lazy" />
				</div>
				<div className="about-right">
					<img src={about2} alt="Harry and Anna Wake, the founders of Mission Encodeable, in front of a colourful Thierry Noir wall graffiti artwork" loading="lazy" />
				</div>
			</section>

			<div className="homepage-bottom-bg">

				<div className="demo-cta">
					<h3 className="darkgreen">If you'd like to use Mission Encodeable with your students, then take a look at our teacher resources.</h3>
					<Link to="/teachers"><button className='btn_green_outline'>Info for teachers</button></Link>
				</div>

				<hr className="block-seperator-green" />

				<section className="home-resources">
					<p className="mini-section-heading mini-section-heading-centred">Blog</p>
					<h3 className="resources-section-intro"><u>Discover the latest</u> blog posts from the Mission Encodeable team</h3>

					<section className='home-blog-posts' id="blog-posts">
						<BlogCardSwiper />
					</section>

				</section>

			</div>

			<NewsletterFooter />

		</div>
	)
}

export default App;
