import React, {useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";

import GetSeasonalProject from "../hooks/getSeasonalProject";

import MailchimpFormContainer from "../components/universal/NewsletterFooter/MailchimpFormContainer";

import summer_header_4 from "../images/pages/summer/summer_header_4.webp";
import summer_header_3 from "../images/pages/summer/summer_header_3.webp";
import summer_header_2 from "../images/pages/summer/summer_header_2.webp";
import summer_header_1 from "../images/pages/summer/summer_header_1.webp";
import summer_me_suitcase from "../images/pages/summer/summer_me_suitcase.svg";
import summer_campervan from "../images/pages/summer/summer_campervan.svg";
import x from "../images/pages/summer/summer_x_logo.svg";
import linkedin from "../images/pages/summer/summer_linkedin_logo.svg";
import facebook from "../images/pages/summer/summer_facebook_logo.svg";

// import these SVGs as ReactComponents so that we can change their colour
import { ReactComponent as PlusIcon } from '../images/universal/icons/plus_icon.svg';
import { ReactComponent as MinusIcon } from '../images/universal/icons/minus_icon.svg';

import "./SummerProjectPage.css";
import ReactHtmlParser from 'html-react-parser';

import HighlightCode from "../HighlightCode";
 
const DOMPurify = require('dompurify');

function SummerProjectPage() {
    let params = useParams();
    let navigate = useNavigate();
    let level_number = params.levelNumber;
    let { title, content_html, colour } = GetSeasonalProject(level_number, 'summer');
    let clean_project_content = DOMPurify.sanitize(content_html, { ADD_TAGS: ["iframe"], ADD_ATTR: ['target', 'allow', 'allowfullscreen', 'frameborder', 'scrolling'] });
    
    let year = new Date().getFullYear();

    useEffect(() => {
        HighlightCode();
    })

    const parsedHTML = ReactHtmlParser(clean_project_content);
    let currentAccordionSection = 0;

    return (
        <div className="summer-home-bg">
            <div className="summer-header">
                <img className="summer-header-1" src={summer_header_1} alt='Summer Coding Challenges header'></img>
                <img className="summer-header-2"src={summer_header_2} alt='Summer Coding Challenges header'></img>
                <img className="summer-header-3"src={summer_header_3} alt='Summer Coding Challenges header'></img>
                <img className="summer-header-4"src={summer_header_4} alt='Summer Coding Challenges header'></img>
                <a href="/" target="_blank"><img src={summer_me_suitcase} className="suitcase-tag" alt=''></img></a>
            </div>

            <section className={`summer-project-top-about`}>
                <div className={`summer-project-top-about-text`}>
                    <button className={`summer-project-page-btn`} onClick={() => navigate(`/summer`)}>← Back to the list of projects</button>
                    <h3>Python Level {level_number}</h3>
                    <h2>{title}</h2>
                    <a className="a-no-decoration" href={`/python/level/${level_number}`} target="_blank" rel="noreferrer"><p className={`start-me-level-link-summer`}>Visit Mission Encodeable Level {level_number}</p></a>
                </div>
            </section>

            <section className={`summer-project-content`}>
                {parsedHTML.map((child, index) => {
                if (child.props && child.props.className === 'accordion-section') {
                    currentAccordionSection = currentAccordionSection + 1
                    return <AccordionSection key={index} sectionNumber={currentAccordionSection} colour={colour}>{child}</AccordionSection>;
                }
                return child;
                })}
            </section>

            <section>
                <img src={summer_campervan} className="summer-newsletter-van" alt=''></img>
                <div className="summer-newsletter">
                    <h2>Teachers: Sign up to our coding newsletter</h2> 
                    <MailchimpFormContainer/>
                </div>
            </section>

            <footer>
                <div className='second-row summer-footer-second-row'>
                        <p className='white'>© Mission Encodeable {year}</p>
                        <div className="summer-social-footer-icons social-footer-icons">
                            <a href="https://www.linkedin.com/company/mission-encodeable/" target="_blank" rel="noreferrer" className="social-link"><img src={linkedin} alt="LinkedIn icon"/></a>
                            <a href="https://x.com/missionencode" target="_blank" rel="noreferrer" className="social-link"><img src={x} alt="X icon"/></a>
                            <a href="https://www.facebook.com/people/Mission-Encodeable/100094369925208/" target="_blank" rel="noreferrer" className="social-link"><img src={facebook} alt="Facebook icon"/></a>
                        </div>
                </div>
            </footer>
        </div>
    )

}

function AccordionSection({children, sectionNumber, colour}) {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleSection = () => {
      setIsOpen(!isOpen);
    };

    useEffect(() => {
        HighlightCode();
    })

    let header;
    let content = [];

    // Iterate through children to separate header and content
    let allContent = children.props.children;
    for (let child of allContent) {
        if (child.type === 'p') {
            header = child;
        } else {
            content.push(child);
        };
    };
  
    return (
      <div className="accordion-section">
        <div className={`accordion-header accordion-header-${colour}`} onClick={toggleSection} style={{ cursor: 'pointer' }}>
            {header}
            {isOpen ?  <MinusIcon className={`accordion-image-christmas accordion-image`}/> : <PlusIcon className={`accordion-image-christmas accordion-image`}/>}
        </div>
        {isOpen && (
          <div className="accordion-foldout">
            {content} {/* Render content within the accordion section */}
          </div>
        )}
      </div>

    );
}

export default SummerProjectPage;