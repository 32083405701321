import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useForm, ValidationError } from '@formspree/react';
import SurveyFooter from "../components/universal/SurveyFooter/SurveyFooter";
import "./RequestADemo.css"
import ReCaptchaV2 from 'react-google-recaptcha'
import hero_image from "../images/components/Hero/request_a_demo_hero_image.webp";
import questions_icon from "../images/universal/icons/questions_icon.svg";
import mission_encodeable_icon from "../images/universal/icons/mission_encodeable_icon.svg";
import students_icon from "../images/universal/icons/students_icon.svg";
import resources_icon from "../images/universal/icons/resources_icon.svg";



function DemoForm() {
  const [state, handleSubmit] = useForm("mpzezopo");
  if (state.succeeded) {
      return <div className="success-message"><div className='success-text'><h3>Thank you!</h3><p>We'll be in touch soon.</p></div></div>;
  }
  return (
      <div className="demo-form">

        <Helmet>
            <title>Request a demo | See how Mission Encodeable could help you</title>
            <meta name="description" content="If you're ready to transform the way your students learn to code, then we’d love to show you Mission Encodeable in action."/>
        </Helmet>


      <form onSubmit={handleSubmit}>

        <label htmlFor="name">Name</label>

        <input id="name" type="text" name="name" required/>
        <ValidationError prefix="Name" field="name" errors={state.errors}/>

        <label htmlFor="email">Teacher email address</label>

        <input id="email" type="email" name="email" required/>

        <ValidationError prefix="Email" field="email" errors={state.errors}/>

        <label htmlFor="name">School / Organisation name</label>

        <input id="school-name" type="text" name="school-name" required/>
        <ValidationError prefix="School / Organisation name" field="school-name" errors={state.errors}/>


        <label htmlFor="comments">Any comments or questions?</label>

        <textarea id="comments" name="comments"/>

        <ReCaptchaV2 className="recaptcha_box" sitekey={`6LeD9-ceAAAAAH3ha0C462iYKaTDr3vM7P-nxaYO`} />
        
        <p className='white'>Please note we only offer demos to teachers and club leaders.</p>

        <button className="btn_orange_fill" type="submit" disabled={state.submitting}>
            Submit
        </button>
      </form>
    </div>

  );
}

function RequestADemo(){
    return(
        <div className='requst_a_demo_page'>

            <section className="requst_a_demo_hero">
                    <div className='text'>
                      <h1>Find out how Mission Encodeable could <em>inspire</em> your students</h1>
                      <p class="darkgreen">If you're a teacher or club leader ready to transform the way your students learn to code, then we'd love to show you Mission Encodeable in action. </p>
                    </div>
                    <div className='hero_image_wrapper'>
                      <img className="hero_img" src={hero_image} alt="Harry Wake and Anna Wake, the Mission Encodeable founders, looking at their laptops and writing in notebooks."/>
                    </div>
            </section>

            <section className="demo-info">
                <h3 className="darkgreen">Request a <u>personalised</u> demo of Mission Encodeable</h3>
                <p className="darkgreen">In your free demo, we'll introduce Mission Encodeable and its key benefits for you and your school. These are designed for teachers and club leaders who are new to Mission Encodeable; if you're already using our platform, visit our <a href="/contact">contact page</a> for quick queries.</p>
            </section>

            <section className='demo-form-section'>

              <div className='form_text'>
                <h3>What's the process?</h3>
                <p>It couldn't be simpler. When you request a demo, we'll get in touch to find a time that works for you.</p>
                <h4>What to expect from a demo</h4>
                <div className="demo-expectations-list">

                    <div className="demo-expectation-item">
                        <img src={questions_icon} alt="An icon showing a speech bubble with a question mark in it."/>
                        <p>We'll ask you a few questions to understand more about your school and your curriculum</p>
                    </div>
                    
                    <div className="demo-expectation-item">
                        <img src={mission_encodeable_icon} alt="The Mission Encodeable logo"/>
                        <p>We'll introduce the Mission Encodeable platform, explaining our story, how we work, and what makes us different</p>
                    </div>

                    <div className="demo-expectation-item">
                        <img src={students_icon} alt="An icon showing a group of students"/>
                        <p>We'll explain how Mission Encodeable can benefit your students</p>
                    </div>

                    <div className="demo-expectation-item">
                        <img src={resources_icon} alt="An icon of a downloadable resource"/>
                        <p>We'll show you some useful resources and help you to implement Mission Encodeable successfully in your school</p>
                    </div>

                </div>
              </div>

              <div className='form_wrapper'>
                <DemoForm/>
              </div>

            </section>

            <section className="not-ready-block">
                <h3 className="darkgreen">Not sure if you need a demo? Take a look at our information and resources for teachers.</h3>
                <Link to="/teachers"><button className="btn_green_outline">Info for teachers</button></Link>
            </section>

            <SurveyFooter/>

        </div>
    )
}

export default RequestADemo;

