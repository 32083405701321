import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";

import NewsletterFooter from "../components/universal/NewsletterFooter/NewsletterFooter";
import BlogCardSwiper from "../components/pages/resources/BlogCardSwiper/BlogCardSwiper.js";

import direction_down from '../images/universal/annotations/direction_down.svg';
import GetBlogPost from "../hooks/getBlogPost";

import "./BlogPost.css";

const parse = require('html-react-parser');
const DOMPurify = require('dompurify');
function BlogPost() {
    let params = useParams();
    let post_slug = params.post_slug;
    let { title, content, date, author, meta_description, alt_text } = GetBlogPost(post_slug);
    let clean_content = DOMPurify.sanitize(content)
    return (
        <div className="post-page-wrapper">

            <Helmet>
                <title>{`${title} | Mission Encodeable Blog`}</title>
                <meta name="description" content={meta_description} />
            </Helmet>

            <div className="blog-post-header">
                <div className="blog-post-info">
                    <h1 className="blog-post-title darkgreen">{title}</h1>
                    <div className="post-meta-info-wrapper">
                        <div className="post-meta-column">
                            <p className="column-field-name">Written by:</p>
                            <p className="column-field-data">{author}</p>
                        </div>
                        <div className="post-meta-column">
                            <p className="column-field-name">Posted:</p>
                            <p className="column-field-data">{date}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-image-and-annotation">
                <img alt={alt_text} src={`../../../../../blog_images/blog-post-${post_slug}-feature-image.webp`} className="blog-header-image" />
                <a href="#blog-content" className="blog-header-annotation"><img alt="An hand-drawn orange arrow pointing downwards" src={direction_down}></img></a>
            </div>


            <div className="blog-content" id="blog-content">
                {parse(`${clean_content}`)}
            </div>

            <section className="discover-more-resources">
                <p className="mini-section-heading mini-section-heading-centred">Blog</p>
                <h3 className="resources-section-intro"><u>Discover the latest</u> blog posts from the Mission Encodeable team</h3>

                <section className='home-blog-posts' id="blog-posts">
                    <BlogCardSwiper />
                </section>

            </section>
            <NewsletterFooter />
        </div>
    )
}
export default BlogPost;