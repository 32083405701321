import React from 'react';
import { Link, useLocation } from "react-router-dom";
import "./Footer.css";

import x from "../../../images/components/Footer/x_logo.svg";
import linkedin from "../../../images/components/Footer/linkedin_logo.svg";
import facebook from "../../../images/components/Footer/facebook_logo.svg"
;
function Footer() {
    const location = useLocation();
	let pathname = location.pathname.toLowerCase();
    let year = new Date().getFullYear();

    if ((pathname.includes("christmas") || pathname.includes("summer")) && !(pathname.includes("blog"))){
		return null
	} else {
        return(
            <div>
                <footer>
                    <div className='first-row'>
                            <Link to='/privacy'>Privacy policy</Link>
                            <div className="social-footer-icons">
                                <a href="https://www.linkedin.com/company/mission-encodeable/" target="_blank" rel="noreferrer" className="social-link"><img src={linkedin} alt="LinkedIn icon"/></a>
                                <a href="https://x.com/missionencode" target="_blank" rel="noreferrer" className="social-link"><img src={x} alt="X icon"/></a>
                                <a href="https://www.facebook.com/people/Mission-Encodeable/100094369925208/" target="_blank" rel="noreferrer" className="social-link"><img src={facebook} alt="Facebook icon"/></a>
                            </div>
                    </div>
                    <div className='second-row'><p className='white'>© Mission Encodeable {year}</p></div>
                </footer>
            </div>
        );
    };
}

export default Footer;