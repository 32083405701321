import React from "react";

import cnd_hero_image from "../images/components/Hero/cnd_hero_image.webp";
import tutorials_illustration from "../images/pages/cnd/tutorials_illustration.svg";
import projects_illustration from "../images/pages/cnd/projects_illustration.svg";
import videos_illustration from "../images/pages/cnd/videos_illustration.svg";

import BlogCardSwiper from "../components/pages/resources/BlogCardSwiper/BlogCardSwiper";

import "./Cnd.css";

function Cnd() {
    return(
        <>
            <section className='about-hero powder-blue-bg'>
                <div className='text'>
                    <h1 class="darkgreen">Better <em>together</em>: Partnership with Craig 'n' Dave</h1>
                    <p class="darkgreen">We've teamed up with Craig and Dave to offer a scheme that has the student's approval and is backed by the latest pedagogical research.</p>
                </div>

                <div className="hero-image-wrapper">
                    <img src={cnd_hero_image} className="hero-img" alt="Craig Sargent and Dave Hillyard, aka Craig'n'Dave." />
                </div>
			</section>

            <section className="about-craig-n-dave">
                <h3>About Craig 'n' Dave</h3>

                <p>Craig 'n' Dave are renowned Computer Science resource publishers with hundreds of schools 
                across the country using their resources in each and every one of their Computer Science 
                lessons. Pioneering the "flipped learning" approach, their popular YouTube channel has over 
                40,000 subscribers, and their ad-free videos have amassed over sixteen million views on the 
                platform.</p>

                <p>They're experts on the latest pedagogical approaches, and the requirements of the different 
                GCSE and A-level qualifications. With over a quarter of a century in the classroom, they have 
                an extensive experience and knowledge of teaching teenagers to code.</p>
                
                <h4>TIME 2 CODE: A new coding scheme</h4>

                <p>Craig 'n' Dave's shiny new platform, TIME 2 CODE, has been designed to help students with the
                programming component of their GCSE Computer Science examinations. It offers an approach based on 
                PRIMM with differentiated and assessment programs, which feature scenarios akin to those which 
                appear in examinations.</p>

                <p>Inspired by our creative approach, strong design ethos and innovative project ideas, Craig 
                and Dave approached Mission Encodeable to ask whether we would be willing to partner with their 
                new platform. Working together, we've aligned the contents of the levels of Mission Encodeable 
                to the new levels in the TIME 2 CODE scheme. This means you can use our tutorials and resources 
                in conjunction with theirs.</p>

                <p>The programs on the TIME 2 CODE platform offer you an academically focused approach for exam 
                preparation; Mission Encodeable has more interesting and fun projects including several games, 
                that will help to engage students and spark a passion for programming. </p>

                <p>Ultimately, by exploring together, we can now offer the Computer Science community a 
                co-ordinated, compatible approach which provides the best of both worlds. Visit the TIME 2 CODE 
                website to see their offering, and discover more below about how our resources align.</p>
                <a href="https://time2code.today" target="_blank" rel="noreferrer" className="a-no-decoration"><button className="btn_green_fill">Visit TIME 2 CODE</button></a>
            </section>

            <section className="cnd-alignment">
                <div className="cnd-alignment-item">
                    <img src={tutorials_illustration} alt="A block-based graphic of some tutorials."></img>
                    <div>
                        <h3>Step-by-step tutorials</h3>
                        <p>Whilst TIME 2 CODE uses a video-based, "live coding" approach, we can offer students 
                        beginner-friendly, step-by-step written tutorials. Together this enables us to accommodate 
                        a range of learning styles, and it also proffers two different explanations of each concept, 
                        so that students can find the one which works for them.</p>
                    </div>
                </div>
                <div className="cnd-alignment-item">
                    <img src={projects_illustration} alt="A block-based graphic of a pong game." className="img-only-show-on-mobile"></img>
                    <div>
                        <h3>Exciting projects</h3>
                        <p>TIME 2 CODE offers an approach based on PRIMM with differentiated and assessment 
                        programs. Typically, programs found in their scheme are more academically focused, and 
                        feature scenarios similar to those found in GCSE examinations.</p>

                        <p>At the heart of our offering, Mission Encodeable's projects include games such as Mad 
                        Libs and Treasure Island. They will help to engage students and can also provide additional 
                        opportunities for assessment.</p>
                    </div>
                    <img src={projects_illustration} alt="A block-based graphic of a pong game." className="img-hide-on-mobile"></img>
                </div>
                <div className="cnd-alignment-item">
                    <img src={videos_illustration} alt="A block-based graphic of some videos."></img>
                    <div>
                        <h3>Syntax videos</h3>
                        <p>Harry and Anna, the co-founders of Mission Encodeable have produced over 
                        fifty engaging bitesize videos for TIME 2 CODE, which explains the syntax of every 
                        Python command featured in the scheme.</p>

                        <p>They're perfect to get students back on track if they're struggling with a 
                        particular aspect of the course, and are available to view for free via Craig 'n' Dave's 
                        YouTube channel.</p>
                    </div>
                </div>
            </section>

            <section className="choosing-a-primary-resource">
                <div>
                    <h4>Mission Encodeable as the primary resource</h4>
                    <p>Students make independent progress with the Mission Encodeable scheme. They work 
                    through the methodical, beginner-friendly tutorials in each of our levels, completing the 
                    relevant learning programs as they go (some of which follow the TIME approach). At the end 
                    of each level, they complete the Mission Encodeable capstone project independently, allowing 
                    them to discover their strengths and work on their weaknesses.</p>

                    <p>The teacher can then use the programs in TIME 2 CODE to lead examples from the front of 
                    the class, to practice flowcharting and to use any of the programs for assessment. When 
                    students have finished a level in Mission Encodeable, if they need more practice before 
                    moving on, they can use the practice programs from TIME 2 CODE.</p>
                </div>

                <div>
                    <h4>TIME 2 CODE as the primary resource</h4>
                    <p>Students work through the TIME 2 CODE scheme at their own pace. Using the learning 
                    programs and videos to understand new concepts before applying them to the practice 
                    programs. However, some students may find reading what they are being taught rather than 
                    just watching videos helpful.</p>

                    <p>Mission Encodeable has more detailed explanations of each of the concepts with examples, 
                    so students can use the Mission Encodeable site as a step-by-step guide. When students have 
                    finished a level in TIME 2 CODE, if they need more practice before moving on, they can use 
                    the Mission Encodeable projects signposted at the end of each level.</p>
                </div>
            </section>

            <section className="home-resources powder-blue-bg cnd-page-blog-section">
                <p className="mini-section-heading mini-section-heading-centred">Blog</p>
                <h3 className="resources-section-intro"><u>Discover the latest</u> blog posts from the Mission Encodeable team</h3>

                <section className='home-blog-posts' id="blog-posts">
                    <BlogCardSwiper />
                </section>

            </section>
        </>
    )
}

export default Cnd;